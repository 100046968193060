* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 500;
  src: url('/assets/font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'KacstDecorative';
  font-weight: normal;
  src: url('/assets/font/KacstDecorative.ttf');
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/font/Gilroy-ExtraBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('/assets/font/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  font-weight: 800;
  src: url('/assets/font/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Black';
  font-weight: 900;
  src: url('/assets/font/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-weight: default;
  src: url('/assets/font/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 500;
  src: url('/assets/font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/font/Manrope-Regular.subset.ttf');
}
body,
html {
  height: 100%;
  overscroll-behavior: none;
}
body {
  background-color: rgba(247, 255, 247, 1);
  background-image: url('/assets/img/Home_bg.png');
  /* 让背景图片覆盖整个容器，并保持宽高比 */
  background-size: cover;

  /* 保持背景图片不滚动 */
  background-attachment: fixed;

  /* 让背景图片居中对齐 */
  background-position: center;

  /* 背景不重复 */
  background-repeat: no-repeat;
}
#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-scrolled {
    background-color: rgba(247, 255, 247, 0);
  }
  .header {
    position: fixed;
    top: 0px;
    width: 100%;

    .container {
      width: 1280px;
      min-height: 92px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 1;

      .navL {
        display: flex;
        align-items: center;
        img {
          width: 170px;
          height: 68px;
        }
      }

      .navR {
        display: flex;
        gap: 40px;
        .store {
          cursor: pointer;
          width: 75px;
          height: 38px;
          margin: auto;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 500;
          line-height: 38px;
          letter-spacing: 0.03em;
          text-align: center;
          color: rgba(13, 14, 18, 0.6);
        }

        .store:hover {
          color: rgba(13, 14, 18, 0.48);
        }

        .store:active {
          color: rgba(13, 14, 18, 0.36);
        }

        .download {
          cursor: pointer;
          line-height: 40px;
          width: 140px;
          height: 40px;
          padding: 16px, 0px, 16px, 0px;
          border-radius: 8px;
          gap: 8px;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0.03em;
          text-align: center;
          color: rgba(255, 255, 255, 1);

          position: relative;

          .qrCode {
            position: absolute;
            top: 60px;
            left: -106px;
            width: 250.29px;
            height: 295.29px;
            border-radius: 12px;
            gap: 18px;
            background-color: rgba(254,224,197, 1);

            .triangle {
              position: absolute;
              top: -15px;
              left: 188px;
              width: 0;
              height: 0;
              img {
                width: 42.7px;
                height: 42.7px;
              }
            }

            .qrcode {
              position: relative;
              margin: 18px;
              width: 214.29px;
              height: 214.29px;
              border-radius: 10px;
              img {
                width: 214.29px;
                height: 214.29px;
              }
            }

            .text {
              margin-left: 38.14px;
              font-family: 'Roboto-Regular';
              font-size: 18px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: right;
              display: flex;
              color: rgba(13, 14, 18, 0.6);
              gap: 4.5px;

              img {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }

  .section {
    height: 524px;
    width: 963px;
    display: flex;
    gap: 200px;
    // justify-content: space-between;

    margin: auto;
    margin-top: 186px;
    margin-bottom: 125px;

    .decoration1 {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 729px;
        height: 438px;
      }
    }

    .decoration2 {
      z-index: -1;
      position: absolute;
      bottom: 0px;
      right: 0px;
      img {
        width: 729px;
        height: 438px;
      }
    }

    .left {
      img {
        max-width: 255.33px;
      }
    }

    .right {
      margin-top: 82.89px;
      .icon {
        img {
          width: 120px;
          height: 120px;
        }
      }
      .name {
        color: rgba(13, 14, 18, 1);
        margin-top: 30px;
        font-family: 'Roboto-Medium';
        font-size: 52px;
        font-style: italic;
        font-weight: 700;
        line-height: 73px;
        letter-spacing: 0em;
        text-align: left;
      }
      .desc {
        margin-top: 16px;
        font-family: 'Roboto-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #0d0e1299;
      }
    }
  }

  .footer {
    bottom: 0px;
    min-height: 208px;
    height: 100%;
    background-color: #0d0e12;
    display: flex;
    justify-content: center;
    // align-items: center;

    .container {
      width: 1280px;
      margin-top: 44px;
      // margin: auto;
      display: flex;
      justify-content: space-between;

      .footerL {
        height: 107px;

        .title {
          font-family: 'Roboto-Medium';
          font-size: 36px;
          font-style: italic;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0em;
          color: #ffffff;
        }

        .copyright {
          font-family: 'Roboto-Medium';
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;

          margin-top: 17px;
          color: #ffffff99;
        }

        .desc {
          font-family: 'Roboto-Medium';
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #ffffff33;
          text-align: center; /* 水平居中 */
          display: flex;
          gap: 4px;

          img {
            margin: auto;
            width: 45px;
            height: 17px;
          }
        }
      }

      .footerR {
        width: 113px;
        margin-top: 16px;

        .item {
          height: 31px;
          line-height: 31px;
          font-family: 'Roboto-Medium';
          font-size: 18px;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(255, 255, 255, 0.8);

          .link {
            cursor: pointer;
          }

          .link:hover {
            color: rgba(255, 255, 255, 0.64);
          }

          .link:active {
            color: rgba(255, 255, 255, 0.48);
          }
        }
      }
    }
  }
}
